import axios from "axios";

export const tryAxiosMultiple = async (
  method: "post" | "get",
  url: string,
  data: Record<string, any>,
  maxAttempts = 3
): Promise<Record<string, any> | undefined> => {
  let attempts = 0;
  let res;
  while (attempts < maxAttempts) {
    try {
      res = await axios[method](url, data);
      return res; // Return the response on success
    } catch (error) {
      attempts++;
      console.error(`Attempt ${attempts} failed: ${error}`);
      if (attempts >= maxAttempts) {
        throw new Error(`Failed to reach ${url}`);
      }
    }
  }
  return res;
};
