"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";
import { useTheme } from "next-themes";

import wordLogoLight from "@/public/imgs/Refdocs_Logo_ColourWhite_SVG.svg";
import wordLogoDark from "@/public/imgs/Refdocs_Logo_Colour_SVG.svg";

import { forwardRef } from "react";

interface ThemedLogoProps extends React.HTMLAttributes<HTMLDivElement> {
  force?: "light" | "dark" | undefined;
  width?: number;
  height?: number;
  sizes?: string;
}

export const ThemedLogo = forwardRef<HTMLDivElement, ThemedLogoProps>(
  (
    {
      force,
      width = 196,
      height = 196,
      sizes = `(max-width: 200px) 100vw,
              (max-width: 200px) 50vw,
  33vw`,
      ...props
    },
    ref
  ) => {
    const router = useRouter();
    const { resolvedTheme } = useTheme();

    return (
      <div className="flex">
        {((!force && resolvedTheme === "dark") || force === "dark") && (
          <div className="flex" data-hide-on-theme="dark">
            <Image
              className="flex h-auto max-w-[250px] p-1"
              src={wordLogoLight ?? "https://refdocs.com/wp-content/uploads/2023/07/RefdocsTM-logo.svg"}
              onClick={() => router.push("/")}
              width={width}
              height={height}
              sizes={sizes}
              style={{ objectFit: "contain" }}
              alt="Word Logo Dark Theme"
            />
          </div>
        )}
        {((!force && resolvedTheme === "light") || !resolvedTheme || force === "light") && (
          <div className="flex" data-hide-on-theme="light">
            <Image
              className="flex h-auto max-w-[250px] p-1"
              src={wordLogoDark ?? "https://refdocs.com/wp-content/uploads/2023/07/RefdocsTM-logo.svg"}
              priority={true}
              onClick={() => router.push("/")}
              width={width}
              height={height}
              sizes={sizes}
              style={{ objectFit: "contain" }}
              alt="Word Logo Light Theme"
            />
          </div>
        )}
      </div>
    );
  }
);

ThemedLogo.displayName = "ThemedLogo";
